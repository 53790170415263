import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Interviewing is Everyone's Job!`}</h1>
    <h2>{`2022-04-28 at 5:30pm`}</h2>
    <p><em parentName="p">{`Duration: 1 hour`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220429T003000Z%2F20220429T013000Z&details=In%20this%20session%20you%27ll%20learn%20how%20being%20better%20at%20interviewing%20can%20make%20you%20better%20at%20your%20job%20and%20help%20drive%20your%20career.%20You%27ll%20get%20solid%20guidance%20for%20how%20to%20ask%20questions%20and%20how%20to%20know%20what%20to%20look%20for.&location=http%3A%2F%2Flovelace-labs.adadev.org&text=Interviewing%20is%20Everyone%27s%20Job%20with%20Jeremy%20Wadsak"
        }}>{`Add To Google Calendar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=In%20this%20session%20you%27ll%20learn%20how%20being%20better%20at%20interviewing%20can%20make%20you%20better%20at%20your%20job%20and%20help%20drive%20your%20career.%20You%27ll%20get%20solid%20guidance%20for%20how%20to%20ask%20questions%20and%20how%20to%20know%20what%20to%20look%20for.&enddt=2022-04-29T01%3A30%3A00%2B00%3A00&location=http%3A%2F%2Flovelace-labs.adadev.org&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-04-29T00%3A30%3A00%2B00%3A00&subject=Interviewing%20is%20Everyone%27s%20Job%20with%20Jeremy%20Wadsak"
        }}>{`Add to Microsoft Calendar`}</a></li>
    </ul>
    <p>{`You might think that interviewing is a distraction from slinging code, but it's important to your company, to your team, and to your career. If you are learn how to interview well then you can make sure the right people join your team, building the kind of team you want to work with. If you're a great interviewer it helps you land the next job and makes you more desirable. Interestingly, assessing people's fit to company values helps you better understand your own alignment with that and where you fit in the company.`}</p>
    <p>{`In this session you'll learn how being better at interviewing can make you better at your job and help drive your career. You'll get solid guidance for how to ask questions and how to know what to look for. You'll get a program you can take to your team to help get everyone else onboard.`}</p>
    <h2>{`Jeremy Wadsack`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3dd442bcec16358c696b9ac29a60f81a/f93b5/Jeremy-Wadsack-thumb.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQADAQAAAAAAAAAAAAAAAAQCAwUG/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAeVdLXFwMlR6DYHIB//EABsQAAIDAAMAAAAAAAAAAAAAAAIDAAERBBIT/9oACAEBAAEFAoYL8smbNKkRSxoKCu3JWIs//8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQMBAT8BI//EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAECAQE/AWP/xAAdEAAABQUAAAAAAAAAAAAAAAAAARARIQIiMUFh/9oACAEBAAY/Ag9OV310KEtiB//EABsQAQADAQADAAAAAAAAAAAAAAEAESExEEFh/9oACAEBAAE/IXKi9gfj4C4NihxbBg4ddZZDoemUMUbVP//aAAwDAQACAAMAAAAQ2D//AP/EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAwEBPxAlcmf/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QqhFf/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUXGR/9oACAEBAAE/EB4LSwC9GvJ2Nxy3MSyMlS6zF3d5wTaAXwtNr7KUN1FsbOe5eQNVobdfJ//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Jeremy Wadsack thumbnail",
            "title": "Jeremy Wadsack thumbnail",
            "src": "/static/3dd442bcec16358c696b9ac29a60f81a/f93b5/Jeremy-Wadsack-thumb.jpg",
            "srcSet": ["/static/3dd442bcec16358c696b9ac29a60f81a/f93b5/Jeremy-Wadsack-thumb.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Jeremy Wadsack is CTO at `}<a parentName="p" {...{
        "href": "https://www.teamsense.com/"
      }}>{`TeamSense`}</a>{`, a 2-year-old startup driving engagement for hourly workers. He has been on the launch team at 8 companies with some successes and some flops. He has built a hiring program at TeamSense where rejected candidates reach out to state it was the best interview they ever had.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      